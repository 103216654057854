<template>
  <div class="phone">
    <div class="phone__group">
      <div class="phone__group--top">
        <div class="phone__input phone__country">
          <authorization-dropdown :options="options" @update:selected-option="handleSelection">
            <form-input-text
              v-model:value="authForm.phoneNumber"
              label=""
              type="number"
              :placeholder="getContent(currentLocaleData, defaultLocaleData, 'enterPhoneNumber')"
              name="phone"
              is-required
              :class="'auth-field'"
            />
          </authorization-dropdown>
        </div>

        <div class="phone__input">
          <form-input-password
            v-model:value="authForm.password"
            name="newPassword"
            :placeholder="getContent(currentLocaleData, defaultLocaleData, 'enterPassword')"
            is-required
            label=""
            class="auth-field"
            @submit="handleSubmit"
          />
        </div>

        <div v-if="type === 'login'" class="phone__reset-password" @click="openResetView">
          {{ getContent(currentLocaleData, defaultLocaleData, 'forgotPassword') }}
        </div>

        <div v-else>
          <div class="phone__bonus-label">
            {{ getContent(fieldsSettings, defaultLocaleFieldsSettings, 'fieldsControls.bonusCode.label') }}
          </div>

          <form-input-text
            v-model:value="authForm.bonusCode"
            label=""
            :placeholder="
              getContent(fieldsSettings, defaultLocaleFieldsSettings, 'fieldsControls.bonusCode.placeholder')
            "
            name="bonus"
            autocomplete="off"
            :class="'auth-field'"
          />
        </div>
      </div>

      <div class="phone__group--bottom">
        <form-input-checkbox v-if="type === 'registration'" class="phone__tc" v-model="authForm.agreeTC">
          <span class="phone__tc-text">
            {{ getContent(currentLocaleData, defaultLocaleData, 'consent') }}

            <NuxtLink :to="to" target="_blank">
              {{ getContent(currentLocaleData, defaultLocaleData, 'termsCondition') }}
            </NuxtLink>
          </span>
        </form-input-checkbox>

        <div class="phone__buttons">
          <button-base
            type="primary"
            size="sm"
            class="phone__register phone__btn"
            :is-disabled="formHasError || isLockedAsyncButton"
            :isProcessing="isLockedAsyncButton"
            @click="handleSubmit"
          >
            {{ buttonSubmitText[type] }}
          </button-base>

          <!-- <div class="phone__auth-login">
            <div class="phone__auth-btn phone__auth-social">
              <atomic-icon-colored id="google" class="phone__auth-icon" />
            </div>

            <div class="phone__auth-btn phone__auth-btn-secondary phone__auth-social">
              <atomic-icon id="telegram" class="phone__auth-icon" />
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ModalName } from '@skeleton/consts/modals';
  import type { IModalsContent } from '~/types';

  const props = defineProps<{
    type: 'registration' | 'login';
    options: ICountry[];
    countryOrigin: string;
    currentLocaleData: Maybe<IModalsContent['authorization']>;
    defaultLocaleData: Maybe<IModalsContent['authorization']>;
  }>();

  const { getContent, localizePath } = useProjectMethods();

  const { phoneRegistration, logIn } = useProfileStore();

  const { defaultLocaleAlertsData, alertsData, fieldsSettings, defaultLocaleFieldsSettings } =
    storeToRefs(useGlobalStore());

  const { showAlert } = useLayoutStore();
  const { closeModalSync, openModal } = useModalStore();

  interface ICountry {
    name: string;
    code: string;
    icon: string;
    phonePrefix: string;
  }

  const { updateAuthState } = useAuthorization();
  const formHasError = ref(true);

  const authForm = reactive({
    agreeTC: false,

    phonePrefix: '376',
    countryCode: 'AD',
    phoneNumber: '',
    password: '',
    bonusCode: '',
  });

  watchEffect(() => {
    if (props.type === 'registration') {
      if (!authForm.agreeTC || !authForm.phoneNumber.length || !authForm.password.length) formHasError.value = true;
      else formHasError.value = false;
    } else {
      if (!authForm.phoneNumber.length || !authForm.password.length) formHasError.value = true;
      else formHasError.value = false;
    }
  });

  const buttonSubmitText = computed(() => ({
    registration: getContent(props.currentLocaleData, props.defaultLocaleData, 'register'),
    login: getContent(props.currentLocaleData, props.defaultLocaleData, 'login'),
  }));

  const to = computed(() => localizePath('/static/bonus-terms'));

  const handleSelection = (option: ICountry) => {
    authForm.phonePrefix = option.phonePrefix;
    authForm.countryCode = option.code;
  };

  const handleSubmit = () => {
    if (props.type === 'login') {
      login();
    } else {
      register();
    }
  };

  const openResetView = () => {
    updateAuthState('isResetPasswordView', true);
  };

  const isLockedAsyncButton = ref(false);
  const register = async () => {
    try {
      isLockedAsyncButton.value = true;
      await phoneRegistration({
        phone: `${authForm.phonePrefix}${authForm.phoneNumber}`,
        password: authForm.password,
        country: props.countryOrigin,
      });
      closeModalSync(ModalName.SIGN_IN);
      closeModalSync(ModalName.SIGN_UP);
    } catch (error: any) {
      const errorResponse = [401, 422, 403];
      if (errorResponse.includes(error.response?.status)) {
        showAlert(
          alertsData.value?.profile?.registrationValidationError ||
            defaultLocaleAlertsData.value?.profile?.registrationValidationError
        );
      }

      if (error.data?.error?.code === 10025) {
        closeModalSync(ModalName.SIGN_IN);
        closeModalSync(ModalName.SIGN_UP);
        openModal(ModalName.GEO_POPUP);
      }
    } finally {
      isLockedAsyncButton.value = false;
    }
  };

  const login = async () => {
    try {
      isLockedAsyncButton.value = true;
      await logIn({ login: `${authForm.phonePrefix}${authForm.phoneNumber}`, password: authForm.password });
      closeModalSync(ModalName.SIGN_IN);
      closeModalSync(ModalName.SIGN_UP);
    } catch (error: any) {
      const errorResponse = [401, 422, 403];
      if (errorResponse.includes(error.response?.status)) {
        showAlert(alertsData.value?.profile?.loginError || defaultLocaleAlertsData.value?.profile?.loginError);
      }
    } finally {
      isLockedAsyncButton.value = false;
    }
  };
</script>

<style src="~/assets/styles/components/authorization/phone.scss" lang="scss" />
