<template>
  <div class="authorization-controller">
    <authorization-tabs v-model:tabs="tabs">
      <template #email>
        <authorization-email
          :type="type"
          :countryOrigin="countryOrigin"
          :currentLocaleData="currentLocaleData"
          :defaultLocaleData="defaultLocaleData"
        />
      </template>

      <template #phone>
        <authorization-phone
          :type="type"
          :options="options"
          :countryOrigin="countryOrigin"
          :currentLocaleData="currentLocaleData"
          :defaultLocaleData="defaultLocaleData"
          @update:selected-option="handleSelection"
        />
      </template>
    </authorization-tabs>
  </div>
</template>

<script setup lang="ts">
  import type { IModalsContent } from '~/types';

  defineProps<{
    type: 'registration' | 'login';
    options: ICountry[];
    countryOrigin: string;
    currentLocaleData: Maybe<IModalsContent['authorization']>;
    defaultLocaleData: Maybe<IModalsContent['authorization']>;
  }>();

  interface ICountry {
    name: string;
    code: string;
    icon: string;
    phonePrefix: string;
  }

  const { canAuthorizeWithEmail, canAuthorizeWithPhone } = storeToRefs(useAuthorization());
  const selectedOption = ref('');

  const handleSelection = (option: string) => {
    selectedOption.value = option;
  };

  const tabs = ref([
    {
      title: 'Email',
      isActive: false,
      key: 'email',
      icon: 'message',
      canShow: true,
    },
    // {
    //   title: 'Phone',
    //   isActive: false,
    //   key: 'phone',
    //   icon: 'phone',
    //   canShow: false,
    // },
  ]);

  const toggleTab = (tabKey: 'email' | 'phone', canShow: boolean) => {
    const foundTab = tabs.value.find(tab => tab.key === tabKey);
    if (foundTab) foundTab.canShow = canShow;
  };

  watchEffect(() => {
    if (canAuthorizeWithEmail.value) toggleTab('email', true);
    else toggleTab('email', false);

    if (canAuthorizeWithPhone.value) toggleTab('phone', true);
    else toggleTab('phone', false);
  });
</script>

<style src="~/assets/styles/components/authorization/controller.scss" lang="scss" />
