interface ICountry {
  name: string;
  code: string;
  icon: string;
  phonePrefix: string;
}

export function useAuthorizationInit() {
  const { getInitUserInfo } = useCoreGlobalApi();
  const { countries: countryData } = storeToRefs(useGlobalStore());
  const { iconUrl: iconFlags } = useDynamicIcon('flags');
  const countryDetails = ref<Array<ICountry>>([]);
  const countryOrigin = ref('');

  const route = useRoute();

  enum AuthorizationTab {
    LOGIN = 'login',
    REGISTER = 'register',
  }

  const currentAuthorizationTab = ref<AuthorizationTab>(AuthorizationTab.LOGIN);

  onBeforeMount(async () => {
    if (countryDetails.value.length) return;
    try {
      const origin = await getInitUserInfo();
      countryDetails.value = countryData.value.map(el => ({
        name: el.name,
        code: el.code,
        icon: `${iconFlags.value}/${el.code.toLowerCase()}.svg`,
        phonePrefix: el.phonePrefix,
      }));
      countryOrigin.value = origin.country;
    } catch (e) {
      console.log('an error occured');
    }
  });

  watch(
    () => route.query,
    query => {
      if (query['sign-in']) {
        currentAuthorizationTab.value = AuthorizationTab.LOGIN;
      } else if (query['sign-up']) {
        currentAuthorizationTab.value = AuthorizationTab.REGISTER;
      }
    },
    { immediate: true }
  );

  return {
    countryDetails,
    countryOrigin,
    currentAuthorizationTab,
    AuthorizationTab,
  };
}
