<template>
  <div class="authorization-banner" @click="redirect">
    <picture v-not-lazy data-not-lazy class="authorization-banner__img">
      <source media="(max-width: 768px)" :data-srcset="mobileImage" />
      <source media="(min-width: 769px)" :data-srcset="desktopImage" />
      <atomic-image :src="defaultImage" not-lazy />
    </picture>

    <div class="authorization-banner-top">
      <div class="authorization-banner-top__text">
        <span class="authorization-banner-top__text--bold">
          {{ getContent(currentLocaleData, defaultLocaleData, 'banner.ratedEvent.rated') }}
        </span>

        {{ getContent(currentLocaleData, defaultLocaleData, 'banner.ratedEvent.category') }}
      </div>

      <div class="authorization-banner-top__text">
        <span class="authorization-banner-top__text--bold">
          {{ getContent(currentLocaleData, defaultLocaleData, 'banner.cashback.value') }}
        </span>

        {{ getContent(currentLocaleData, defaultLocaleData, 'banner.cashback.text') }}
      </div>
    </div>

    <div class="authorization-banner-bottom">
      <div class="authorization-banner-bottom__text authorization-banner-bottom__text--message">
        {{ getContent(currentLocaleData, defaultLocaleData, 'banner.bonusMessage') }}
      </div>

      <div
        class="authorization-banner-bottom-promotion-offer"
        :style="{
          background: getContent(currentLocaleData, defaultLocaleData, 'banner.bonusValueBackground'),
          borderColor: getContent(currentLocaleData, defaultLocaleData, 'banner.bonusValueBorderColor'),
        }"
      >
        <span class="authorization-banner-bottom-promotion-offer__text--bold">
          {{ getContent(currentLocaleData, defaultLocaleData, 'banner.bonusValue') }}
        </span>

        <span>{{ getContent(currentLocaleData, defaultLocaleData, 'banner.bonusTextValue') }}</span>
      </div>

      <div class="authorization-banner-bottom__text">
        {{ getContent(currentLocaleData, defaultLocaleData, 'banner.bonusSubValue') }}
      </div>

      <div class="authorization-banner-bottom__text-casino">
        {{ getContent(currentLocaleData, defaultLocaleData, 'banner.bonusSubTextValue') }}
      </div>
    </div>

    <div class="authorization-banner-mobile">
      <div class="authorization-banner-mobile__text">
        {{ getContent(currentLocaleData, defaultLocaleData, 'banner.bonusMessage') }}
      </div>

      <div
        class="authorization-banner-bottom-promotion-offer"
        :style="{
          background: getContent(currentLocaleData, defaultLocaleData, 'banner.bonusValueBackground'),
          borderColor: getContent(currentLocaleData, defaultLocaleData, 'banner.bonusValueBorderColor'),
        }"
      >
        <span class="authorization-banner-bottom-promotion-offer__text--bold">
          {{ getContent(currentLocaleData, defaultLocaleData, 'banner.bonusValue') }}
        </span>

        <span>{{ getContent(currentLocaleData, defaultLocaleData, 'banner.bonusTextValue') }}</span>
      </div>

      <div class="authorization-banner-mobile__text">
        {{ getContent(currentLocaleData, defaultLocaleData, 'banner.bonusSubValue') }}

        <span class="authorization-banner-mobile__text--small"
          >{{ getContent(currentLocaleData, defaultLocaleData, 'banner.bonusSubTextValue') }}
        </span>
      </div>

      <div class="authorization-banner-mobile-bottom">
        <div class="authorization-banner-mobile-bottom__item">
          <span class="authorization-banner-mobile-bottom__text--bold">
            {{ getContent(currentLocaleData, defaultLocaleData, 'banner.ratedEvent.rated') }}
          </span>

          <span class="authorization-banner-mobile-bottom__text--opacity">
            {{ getContent(currentLocaleData, defaultLocaleData, 'banner.ratedEvent.category') }}
          </span>
        </div>

        <div class="authorization-banner-mobile-bottom__item">
          <span class="authorization-banner-mobile-bottom__text--bold">
            {{ getContent(currentLocaleData, defaultLocaleData, 'banner.cashback.value') }}
          </span>

          <span class="authorization-banner-mobile-bottom__text--opacity">
            {{ getContent(currentLocaleData, defaultLocaleData, 'banner.cashback.text') }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { IModalsContent } from '~/types';

  defineProps<{
    currentLocaleData: Maybe<IModalsContent['authorization']>;
    defaultLocaleData: Maybe<IModalsContent['authorization']>;
  }>();

  const emits = defineEmits<{
    (event: 'closeModal'): void;
  }>();

  const deviceStore = useDeviceStore();
  const { isMobile } = storeToRefs(deviceStore);
  const { getContent, localizePath } = useProjectMethods();

  const {
    public: { gamehubCdn },
  } = useRuntimeConfig();

  const router = useRouter();

  const mobileImage = `${gamehubCdn}/images/login-zeus_mobile.webp`;
  const desktopImage = `${gamehubCdn}/images/login-zeus.webp`;

  const defaultImage = computed(() => (isMobile.value ? mobileImage : desktopImage));

  const redirect = async () => {
    emits('closeModal');
    await router.push(localizePath('/welcome-package'));
  };
</script>

<style src="~/assets/styles/components/authorization/banner.scss" lang="scss" />
