<template>
  <vue-final-modal v-model="isAuthModalOpen" class="modal-sign-in" :click-to-close="false">
    <div class="modal-sign-in__container">
      <div class="modal-sign-in__close--icon" @click="closeModal">
        <atomic-icon id="close" />
      </div>

      <authorization-banner
        :currentLocaleData="popupsData?.['authorization']"
        :defaultLocaleData="defaultLocalePopupsData?.['authorization']"
        @closeModal="closeModal"
      />

      <authorization-form
        :currentLocaleData="popupsData?.['authorization']"
        :defaultLocaleData="defaultLocalePopupsData?.['authorization']"
      />
    </div>
  </vue-final-modal>
</template>

<script setup lang="ts">
  import { VueFinalModal } from 'vue-final-modal';

  import { ModalName } from '@skeleton/consts/modals';

  const modalStore = useModalStore();
  const { closeModalSync } = modalStore;
  const globalStore = useGlobalStore();
  const { popupsData, defaultLocalePopupsData } = storeToRefs(globalStore);

  const route = useRoute();

  const isAuthModalOpen = computed({
    get: () => !!route.query['sign-in'] || !!route.query['sign-up'],
    set: value => {
      if (!value) {
        closeModal();
      }
    },
  });

  const closeModal = () => {
    closeModalSync(ModalName.SIGN_IN);
    closeModalSync(ModalName.SIGN_UP);
  };
</script>

<style src="~/assets/styles/components/modal/sign-in.scss" lang="scss" />
