import { ModalName } from '@skeleton/consts/modals';
import type { ITgLoginData } from '@skeleton/core/types';

export const useTelegramLogin = () => {
  const { showAlert } = useLayoutStore();
  const { alertsData, defaultLocaleAlertsData } = storeToRefs(useGlobalStore());
  const {
    public: { botID, domainToBotMap },
  } = useRuntimeConfig();
  const { handleLogin, syncTelegramUser } = useProfileStore();
  const { submitTelegramLoginData, submitTelegramConnectionData } = useCoreAuthApi();
  let authType = '';

  const isTelegramLoginInProgress = ref(false);

  const triggerTelegramLogin = (type: string) => {
    const BOT_ID = domainToBotMap[window.location.hostname] ?? botID?.toString();
    if (window.Telegram && BOT_ID.length) {
      authType = type;
      window.Telegram.Login.auth(
        {
          bot_id: BOT_ID,
          request_access: 'write',
        },
        onTelegramAuth
      );
    } else {
      console.error('Telegram widget is not loaded yet!');
    }
  };

  const onTelegramAuth = async (user: any) => {
    const { closeModalSync } = useModalStore();

    const userData = new URLSearchParams(user);

    try {
      isTelegramLoginInProgress.value = true;

      if (authType === 'add-connection') {
        await handleAddTelegramConnection(userData);
      } else {
        await handleTelegramAuth(userData);
      }

      await syncTelegramUser(user);
      await closeModalSync(ModalName.SIGN_IN);
      await closeModalSync(ModalName.SIGN_UP);
    } catch (error: any) {
      const errorResponse = [400, 401, 422, 403];
      const errorCode = error.response?._data?.error?.code;

      if (errorCode === 11009) {
        showAlert(
          alertsData.value?.profile?.socialConnectionAlreadyExists ||
          defaultLocaleAlertsData.value?.profile?.socialConnectionAlreadyExists
        );
      } else if (errorResponse.includes(error.response?.status)) {
        showAlert({
          title: error.response._data.error.message || defaultLocaleAlertsData.value?.profile?.loginError,
          type: 'error',
        });
      }
    } finally {
      isTelegramLoginInProgress.value = false;
    }
  };

  const handleTelegramAuth = async (userData: any) => {
    const route = useRoute();
    const { getFromStorage } = useBrowserStorage(true);
    const affiliateTag = getFromStorage('affiliateTag');
    let refId = route.query?.ref_id;
    refId = Array.isArray(refId) ? refId[0] : refId;

    const tgLoginData: ITgLoginData = {
      initDataRaw: userData.toString(),
    };

    if (affiliateTag) {
      tgLoginData.affiliateTag = affiliateTag;
    }

    if (refId) {
      tgLoginData.refId = refId;
    }

    const data = await submitTelegramLoginData(tgLoginData);
    await handleLogin(data);
  };

  const handleAddTelegramConnection = async (userData: any) => {
    const { getSocialConnections } = useProfileStore();

    const tgLoginData: ITgLoginData = {
      initDataRaw: userData.toString(),
    };

    await submitTelegramConnectionData(tgLoginData);
    await getSocialConnections();
  };

  return {
    triggerTelegramLogin,
    isTelegramLoginInProgress,
    botID,
  };
};
