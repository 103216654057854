<template>
  <div class="dropdown">
    <div class="dropdown__group">
      <div class="dropdown__input">

        <div class="dropdown__country-box" @click="toggleDropdown">
          <div class="dropdown__item--icon">
            <atomic-image
              :src="selectedOption?.icon ?? options[0]?.icon"
              class="dropdown__icon"
            />
          </div>
          <span>+{{ selectedOption?.phonePrefix ?? options[0]?.phonePrefix }}</span>
          <atomic-icon
            :id="`arrow_expand-${showDropdown ? 'close' : 'open'}`"
          />
        </div>
        <div class="dropdown__country-box__right">
          <slot />
        </div>

        <div v-show="showDropdown" class="dropdown__container">
          <div class="dropdown__search-group">
            <atomic-icon id="search" class="dropdown__icon-search" />
            <input
              v-model="searchTerm"
              class="dropdown__search"
              type="text"
              placeholder="Search..."
              @focus="showDropdown = true"
              @blur="hideDropdown"
              @input="filterOptions"
            >
          </div>

          <div class="dropdown__menu-b">
            <ul class="dropdown__menu">
              <li
                v-for="option in filteredOptions"
                :key="option.name"
                @mousedown="selectOption(option)"
              >
                <div class="dropdown__item">
                  <div class="dropdown__item--icon">
                    <atomic-image :src="option.icon" class="dropdown__icon" />
                  </div>
                  <span> + {{ option.phonePrefix }} ({{ option.name }}) </span>
                </div>
              </li>
            </ul>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

  const searchTerm = ref('');
  const showDropdown = ref(false);

  interface IProps {
    options: {
      name: string;
      code: string;
      icon: string;
      phonePrefix: string;
    }[];
  }


  function toggleDropdown() {
    showDropdown.value = !showDropdown.value;
  }
  const props = defineProps<IProps>();
  const emit = defineEmits(['update:selectedOption']);
  const filteredOptions = ref<IProps['options']>(props.options);
  const selectedOption = ref<IProps['options'][0] | null>(null);

  const filterOptions = () => {
    const term = searchTerm.value.toLowerCase();
    filteredOptions.value = props.options.filter(option =>
      option.name.toLowerCase().includes(term)
    );
  };

  const selectOption = (option: IProps['options'][number]) => {
    searchTerm.value = option.name;
    showDropdown.value = false;
    selectedOption.value = option;
    emit('update:selectedOption', option);
  };

  function resetDropdown() {
    showDropdown.value = false;
    searchTerm.value = '';
    filteredOptions.value = props.options;
  }

  const hideDropdown = () => {
    setTimeout(resetDropdown, 200);
  };

  watch(
    () => showDropdown.value,
    () => {
      if (!showDropdown.value) resetDropdown();
    }
  );
  watch(() => props.options, filterOptions);
</script>

<style src="~/assets/styles/components/authorization/dropdown.scss" lang="scss" />
