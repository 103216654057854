<template>
  <div class="authorization-form">
    <tab-simple v-model:value="currentAuthorizationTab" :items="authorizationTabs" />

    <authorization-controller
      :type="currentAuthorizationTab === AuthorizationTab.LOGIN ? 'login' : 'registration'"
      :options="countryDetails"
      :country-origin="countryOrigin"
      :currentLocaleData="currentLocaleData"
      :defaultLocaleData="defaultLocaleData"
    />
  </div>
</template>

<script setup lang="ts">
  import type { IModalsContent } from '~/types';

  const props = defineProps<{
    currentLocaleData: Maybe<IModalsContent['authorization']>;
    defaultLocaleData: Maybe<IModalsContent['authorization']>;
  }>();

  const { getContent } = useProjectMethods();

  const { countryDetails, countryOrigin, currentAuthorizationTab, AuthorizationTab } = useAuthorizationInit();

  const authorizationTabs = computed(() => {
    return [
      {
        id: AuthorizationTab.LOGIN,
        name: getContent(props.currentLocaleData, props.defaultLocaleData, 'login'),
        disabled: false,
      },
      {
        id: AuthorizationTab.REGISTER,
        name: getContent(props.currentLocaleData, props.defaultLocaleData, 'register'),
        disabled: false,
      },
    ];
  });
</script>

<style src="~/assets/styles/components/authorization/form.scss" lang="scss" />
